import $axios from '@/core/api.config'
import { generateRequestParams } from '@/core/helper-functions'

class OrdersService {
  // get list of orders
  allOrders = true
  getOrdersList (allOrders, startDate, endDate) {
    return $axios.get(`/adv/orders?allOrders=${allOrders}&startDate=${startDate}&endDate=${endDate}`)
  }

  // get order by id
  getOrder (id) {
    return $axios.get(`/adv/orders/${id}`)
  }

  fulfillOrderItem (orderId, data) {
    return $axios.post(`/adv/orders/${orderId}/fulfill_order_line/`, data)
  }

  downloadOrdersFile (params = {}) {
    return $axios.get('/adv/orders/export', {
      params: generateRequestParams(params),
      responseType: 'blob'
    })
  }
}

export default new OrdersService()
