<template>
  <div>
    <el-button class="mb-32" @click="$router.push({ name: 'Orders' })">Back</el-button>

    <el-card class="mb-32 px-16 sm:px-24">
      <table-component
        v-if="orderData"
        :order-data="orderData"
        :is-loading="buttonLoading"
        @fulfill-order="showModal = true"
      />
    </el-card>

    <div class="mt-24">
      <div class="flex items-center ">
        <el-status status="open" />
        <p class="ml-4">Status explainer</p>
      </div>
      <div class="flex items-center mt-2">
        <el-status status="fulfilled" />
        <p class="ml-4">Status explainer</p>
      </div>
      <div class="flex items-center mt-2">
        <el-status status="partially_fulfilled" />
        <p class="ml-4">Status explainer</p>
      </div>
      <div class="flex items-center mt-2">
        <el-status status="closed" />
        <p class="ml-4">Status explainer</p>
      </div>
    </div>

    <fulfill-order-modal
      :show-modal="showModal"
      :on-fulfill-order="(trackingNumber, notify) => onFulfillOrder(trackingNumber, notify)"
      :button-loading="buttonLoading"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import OrdersService from '@/services/orders.service'
import { mapActions } from 'vuex'

const ElCard = () => import('@/components/shared/ELCard')
const ElStatus = () => import('@/components/shared/ELStatus')
const ElButton = () => import('@/components/shared/ELButton')
const TableComponent = () => import('@/components/order/Table')
const FulfillOrderModal = () => import('@/components/FulfillOrderModal')

export default {
  name: 'OrderPage',

  components: { ElCard, ElStatus, ElButton, TableComponent, FulfillOrderModal },

  data () {
    return {
      orderData: null,
      buttonLoading: false,
      showModal: false
    }
  },

  computed: {
    getOrderItemsIds () {
      if (this.orderData) return this.orderData.line_items.map(orderItem => orderItem.id)
      return []
    }
  },

  created () {
    this.firstLoad()
  },

  methods: {
    ...mapActions(['loading', 'showNotification']),

    firstLoad () {
      this.loading(true)

      const { orderId } = this.$route.params

      return OrdersService.getOrder(orderId)
        .then(res => {
          res.line_items = this.parseLineItems(res.line_items)
          this.orderData = res

          this.loading(false)
          return res
        })
    },

    parseLineItems (data) {
      return data.map(item => {
        item.line_item = JSON.parse(item.line_item)
        return item
      })
    },

    onFulfillOrder (trackingNumber, notify) {
      const orderItemsId = this.orderData.line_items.map(orderItem => orderItem.id)

      this.buttonLoading = true
      OrdersService.fulfillOrderItem(this.orderData.order.id, { lineIds: orderItemsId, trackingNumber, notify: !notify })
        .then(res => {
          this.buttonLoading = false
          this.showModal = false
          this.firstLoad()
            .then(res => {
              this.showNotification({ type: 'success', message: 'Order successfully captured' })
            })
        })
        .catch(() => {
          this.buttonLoading = false
          this.showNotification({ type: 'error', message: 'Something went wrong. Please, try again later.' })
        })
    }
  }
}
</script>
